import ruComponents from "./ru.components";
import ruPages from "./ru.pages";
import ruPlural from "./ru.plural";
import ruPipes from "./ru.pipes";

const ru = {
    accessDenied: "Доступ запрещен",
    add: "Добавить",
    all: "Все",
    back: "Назад",
    cancel: "Отмена",
    category: "Категория",
    comment: "Комментарий",
    close: "Закрыть",
    cntShort: "шт.",
    components: ruComponents,
    confirm: "Подтвердить",
    complete: "Завершить",
    date: "Дата",
    delete: "Удалить",
    description: "Описание",
    download: "Скачать",
    deadline: "Крайний срок",
    digit: "Число",
    edit: "Редактировать",
    error: "Ошибка",
    example: "Образец",
    employee: "Сотрудник",
    filters: "Фильтры",
    fullName: "ФИО",
    image: "Изображение",
    id: "ID",
    loading: "Загрузка...",
    money: "₽",
    moneyShort: "руб.",
    name: "Наименование",
    never: "Никогда",
    next: "Далее",
    no: "Нет",
    notFound: "Не найдено",
    ok: "OK",
    path: "Путь",
    pages: ruPages,
    pipes: ruPipes,
    plural: ruPlural,
    photo: "Фото",
    region: "Регион",
    report: "Отчет",
    record: "Запись",
    result: "Результат",
    save: "Сохранить",
    search: "Поиск",
    send: "Отправить",
    selectValue: "Укажите значение",
    sec: "сек.",
    start: "Начало",
    status: "Статус",
    s_of_s: "%s из %s",
    tags: "Теги",
    to: "до",
    type: "Тип",
    tradePoint: "Кофейня",
    unknown: "Неизвестно",
    view: "Просмотр",
    video: "Видео",
    value: "Значение",
    warning: "Внимание",
    yes: "Да",
    you: "Вы",
    or: "или",
    order: "Порядок",
};
export default ru;
