import { ISelectValue } from "components/Inputs/Select";

import { enumToArray, enumToValuesArray, getEnumValue } from "api/common/enumHelper";
import { ISortData } from "api/interfaces/data/ISortData";
import { SortOrderEnum } from "api/interfaces/components/GoodTable";

import ruLang from "./ru";

type TLangTypes = "ru";
const pluralLib = require("plural-ru");
const shortName: TLangTypes = "ru";
const name = "ru-RU";

interface ILangPlural {
    [key: string]: string[];
}
interface ILangPipes {
    [key: string]: { [key2: string]: string };
}

//const lang = require("./" + shortName).default;
const lang = ruLang;

export function getLangValue(langObject: any, field: string | number, defValue: string = "") {
    if (field in langObject) {
        return langObject[field];
    }
    return defValue;
}

export function getLocaleName(type = "") {
    switch (type) {
        case "short":
            return shortName;
        default:
            return name;
    }
}

export function combine(words: string[]) {
    return words
        .map((word, index) => {
            const firstLetter = index === 0 ? word.charAt(0).toUpperCase() : word.charAt(0).toLowerCase();
            return firstLetter + word.slice(1);
        })
        .join(" ");
}

export function plural(count: number, text: string, withCount = true) {
    const startPos = text.indexOf("%p#");
    const endPos = text.indexOf("#", startPos + 3);
    const name = text.substring(startPos + 3, endPos);
    const langPlural = lang.plural as ILangPlural;

    if (!!langPlural?.[name]?.length) {
        const arr = langPlural[name];
        let pluralText = pluralLib(count, ...arr);
        if (withCount) {
            pluralText = `${count} ${pluralText}`;
        }
        return text.replace("%p#" + name + "#", pluralText);
    }
    return text;
}
export function sprintf(text: string, ...values: (string | number)[]): string {
    if (typeof text == "string" && values?.length) {
        for (const value of values) {
            text = text.replace("%s", value.toString());
        }
    }
    return text;
}
export function getEnumTitleValue(enumObject: any, enumName: string, enumValue: number, defaultTitle = "") {
    const value = getEnumValue(enumObject, enumValue);
    if (value !== null) {
        return getEnumTitle(enumName, value);
    }
    return defaultTitle;
}

export function getEnumTitle(enumName: string, valueName: string) {
    enumName = enumName.charAt(0).toLocaleLowerCase() + enumName.slice(1);
    if (enumName.endsWith("Enum") || enumName.endsWith("Flag")) {
        enumName = enumName.slice(0, -4);
    }
    valueName = valueName.charAt(0).toLocaleLowerCase() + valueName.slice(1);
    const langPipes = lang.pipes as ILangPipes;
    return !!langPipes?.[enumName]?.[valueName] ? langPipes?.[enumName]?.[valueName] : valueName;
}

export function getEnumSelectValues(enums: any, enumName: string, sortParams?: ISortData): ISelectValue[] {
    const enumNames = enumToArray(enums);
    const enumValues = enumToValuesArray(enums);

    const result: ISelectValue[] = [];
    for (let i = 0; i < enumNames.length; i++) {
        result.push({
            id: enumValues[i],
            title: getEnumTitle(enumName, enumNames[i].toString()),
        });
    }

    if (sortParams) {
        const sortedResult = sortArray(result, sortParams.sort);
        return sortParams.direction === SortOrderEnum.Ascending ? sortedResult : sortedResult.reverse();
    }

    return result;
}

export function sortArray(
    arr: any[],
    fieldName: string,
    options: Intl.CollatorOptions | undefined = { numeric: true }
) {
    const sorter = getSortArrayCollator(options);
    return arr.sort((a, b) => {
        return sorter.compare(a[fieldName], b[fieldName]);
    });
}
export function getSortArrayCollator(options: Intl.CollatorOptions | undefined = { numeric: true }) {
    return new Intl.Collator(name, options);
}

export default lang;
