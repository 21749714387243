import { ReactNode, useMemo } from "react";
import { Avatar, Backdrop, Box, Typography } from "@mui/material";

import lang from "lang";

interface ILoaderContentProps {
    children?: ReactNode;
    text?: string;
    animate?: boolean;
    isNotAbsolutePosition?: boolean;
}
interface ILoaderProps extends ILoaderContentProps {
    isLoading?: boolean;
    zIndex?: number;
    fullScreen?: boolean;
}
export default function Loader({ isLoading = true, fullScreen = true, zIndex, ...props }: ILoaderProps) {
    if (fullScreen) {
        return (
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => (zIndex ? zIndex : theme.zIndex.drawer + 1) }}
                open={isLoading}
            >
                <LoaderContent
                    {...props}
                    isNotAbsolutePosition={fullScreen}
                />
            </Backdrop>
        );
    }
    if (!isLoading) {
        return null;
    }
    return <LoaderContent {...props} />;
}
export const LoaderContent = ({
    animate = true,
    text = "",
    children = undefined,
    isNotAbsolutePosition = false,
}: ILoaderContentProps) => {
    const animationSx = useMemo(() => {
        if (animate) {
            return {
                animation: "spin 2s linear infinite",
                "@keyframes spin": { "100%": { transform: "rotate(360deg)" } },
            };
        }
        return {};
    }, [animate]);
    const sxProps = useMemo(() => {
        if (!isNotAbsolutePosition) {
            return { position: "absolute", left: 0, top: 0, right: 0, bottom: 0 };
        }
        return {};
    }, [isNotAbsolutePosition]);

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                ...sxProps,
            }}
        >
            {!!children ? (
                children
            ) : (
                <>
                    <Avatar
                        src="./assets/images/cm_logo.png"
                        variant="square"
                        sx={{
                            ...animationSx,
                            width: 200,
                            height: 200,
                        }}
                    />
                    <Typography>{!!text ? text : lang.components.loader.defaultText}</Typography>
                </>
            )}
        </Box>
    );
};
